import React, { Dispatch, ReactNode, SetStateAction } from 'react';
import { ITableActionTopbarButton } from '../../Components/UI/TableActionTopbar';
import {
  ListagemArquivosActions,
  ListagemArquivosActionUnion
} from '../../Store/ListagemArquivos/ListagemArquivos.actions';
import { ISignalRArchive, IUploadFileData } from '../../Data/interfaces/Upload/IUploadFileData';
import { UploadActions } from '../../Store/Upload/Upload.actions';
import {
  SeparatorsData,
  IVersionField
} from '../../Data/interfaces/Nomenclatura/INomenclatureData';
import { IFileRenameData } from '../../Data/interfaces/ListagemArquivos/IFileRenameRequest';
import { IUpdateFileInfoRequest } from '../../Data/interfaces/Obra/IUpdateFileInfoRequest';
import {
  IFileData,
  IBreadCrumbItem,
  FileStatusEnum,
} from '../../Data/interfaces/ListagemArquivos/IListFilesResponse';
import { msplit, prop } from '../../Utils/toolBox';
import Tag from '../../Components/UI/Tag';
import { ColorName } from '../../Utils/Color';
import { IObraData } from '../../Data/interfaces/Obra/IObraData';
import { IBreadCrumbItem as IBreadcrumbItemResponse } from '../../Data/interfaces/ListagemArquivos/IListFilesResponse';
import emptyListImage from '../../Static/images/computer-upload.png';

import styles from './ListagemArquivos.module.scss';
import { history, store } from '../../Store';
import { ITipoOption } from './Modals/FilterModal';
import AccessPermission from '../../Utils/AcessPermission';
import MoverArquivos from './MoverArquivos';
import toastHandler from '../../Utils/toastHandler';
import Tooltip from '../../Components/UI/Tooltip';
import {
  HAS_LOCKED_FILES_REQUEST_PLOT,
  HAS_LOCKED_FILES_REQUEST_PLOT_EXTENSION
} from '../../Utils/Messages';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { ViewerActions } from '../../Store/Viewer/Viewer.actions';
import { IAutodeskViewer } from '../../Data/interfaces/Viewer/IAutodeskViewer';
import { GetDisciplineApiFolderIdFromBreadCrumb } from 'Utils/ObraUtils';
import { extensions2dAnd3d, allOtherExtensions } from 'Utils/ViewerUtils';
import {
  UpdateStatusActions,
  UpdateStatusActionUnion
} from 'Store/UpdateStatusFileList/UpdateStatus.actions';
import { getIconApi, ApisEnumLabel } from 'Data/enums/Apis';
import { IListFilesRequest } from 'Data/interfaces/ListagemArquivos/IListFilesRequest';
import { Icon, IconName } from 'Components/UI';
import AppStorage from '../../Utils/AppStorage';
import { ISharedUser } from 'Data/interfaces/User/ISharedUser';
import { IOpenVisualizationBlockedFileAction } from '.';
import ModalBloquedSADownload from 'Components/UI/ModalBloquedSADownload';
import QRCodePosition from './Components/QRCodePosition';
import { QRCodePositionEnum } from 'Data/interfaces/Plotagem/IPlotRequestRequest';
import LotePlotagens from './Components/LotePlotagens';
import { Mixpanel } from 'Utils/MixPanel';
import { IUserInforResponse } from 'Data/interfaces/Auth/IUserInfoResponse';
import { ITenantData } from 'Data/interfaces/Tenant/ITenantData';
import { v4 as uuid } from "uuid";

export interface IButtonsTableToolbar {
  showEditTable: boolean;
  selectedRowKeys: string[];
  selectedFiles: any[];
  listFiles: IFileData[];
  obra: IObraData;
  ConstructionSiteDisciplinesId: number;
  isDownloading?: boolean;
  openModalExclusaoArquivos?: () => void;
  openModalDownloadMultiplesFiles?: (hasFolder: boolean) => void;
  openModalDownloadBlockedFiles?: (
    hasPermission: boolean,
    isBlocked: boolean,
    hasBlockedFolders: boolean,
    hasMultipleFiles: boolean,
  ) => void;
  onDownload?: () => void;
  desarquivarArquivos?: () => void;
  openModalCriarPasta?: () => void;
  dispatch: Dispatch<ListagemArquivosActionUnion | UpdateStatusActionUnion>;
  ObsoleteFolderId?: string;
  breadCrumbItemId?: number | string;
  isObsoleteFolder?: boolean;
  constructionSiteId: number;
  folderId: string;
  folderName?: string;
  disciplineName?: string | null;
  urlFolder?: string;
  platform: number;
  breadCrumbPreviousItemId: string | null;
  planIsFree?: boolean;
  isLoadingFilesObsoleteList?: boolean;
  currentUserPrivileges?: ISharedUser;
  userInfo?: IUserInforResponse;
  currentTenant?: ITenantData | null;
  onOpenHistoryDrawer: (fileData?: IFileData) => void;
  flags: {
    downloadViaStream?: boolean;
  }
}

export interface IListSaveOrder {
  dataIndex: string;
  sortOrder: boolean;
}

export const updateUploadState = (
  files: FileList | null,
  match: any,
  obra: IObraData,
  breadCrumbItem: IBreadCrumbItem,
  dispatch: Dispatch<any>,
  ConstructionSiteDisciplinesId?: number,
  maxQtyUploadFiles?: number,
  onOpenModalUpgradeUpload?: () => void,
  folderName?: string | null,
  disciplineName?: string | null,
) => {
  if (files) {
    const disciplineApiFolderId = GetDisciplineApiFolderIdFromBreadCrumb(breadCrumbItem);
    const {
      constructionSiteId,
      folderId,
    } = match.params;

    const breadCrumbId = breadCrumbItem?.PreviousItem?.BreadCrumbItemId && breadCrumbItem.PreviousItem?.BreadCrumbItemId > 0
      ? breadCrumbItem.PreviousItem.BreadCrumbItemId
      : null;
    const breadCrumbIdDropbox = breadCrumbItem.PreviousItem?.BreadCrumbItemIdDropbox && !breadCrumbItem.PreviousItem.BreadCrumbItemIdDropbox.includes('00000000-0000-0000-0000-000000000000')
      ? breadCrumbItem.PreviousItem.BreadCrumbItemIdDropbox
      : null;

    const { uploadFileList } = store.getState().upload;
    const uploadFiles: IUploadFileData[] = Object.keys(files)
      .filter((key: any) => files[key].size)
      .map((key: any) => {
        const fileIdUpload = uuid();
        return {
          csId: constructionSiteId || obra.ConstructionSiteId,
          folderId: folderId || breadCrumbItem.Identifier,
          trackFolderName: folderName,
          disciplineApiFolderId: disciplineApiFolderId,
          trackDisciplineName: disciplineName,
          file: files[key],
          isSending: false,
          sentSize: 0,
          totalSize: files[key].size,
          uploadCompleted: false,
          Api: obra.Api,
          inViewer: false,
          constructionSiteDisciplineId: ConstructionSiteDisciplinesId,
          uploadBreadcrumbFile: {
            fileParentId: breadCrumbItem.Identifier,
            fileParentBreadcrumbId: breadCrumbId || breadCrumbIdDropbox,
          },
          fileIdUpload,
        } as IUploadFileData
      });

    if (maxQtyUploadFiles) {
      if (onOpenModalUpgradeUpload && uploadFileList) {
        if (uploadFileList.length > maxQtyUploadFiles) {
          onOpenModalUpgradeUpload();
        }
      }

      if (onOpenModalUpgradeUpload && (uploadFiles.length > maxQtyUploadFiles)) {
        onOpenModalUpgradeUpload();
      }

      if (uploadFiles.length <= maxQtyUploadFiles) {
        dispatch(UploadActions.addNewUploadFiles(uploadFiles));
      }
    } else {
      dispatch(UploadActions.addNewUploadFiles(uploadFiles));
    }
  }
};

export const renameFile = (
  values: any,
  breadCrumbItem: IBreadcrumbItemResponse,
  dispatch: Dispatch<ListagemArquivosActionUnion>
) => {
  let newName = '';

  if (values.hasNomenclatureControl === 'true') {
    for (let i = 0; values[`field${i}`] !== undefined; i++) {
      newName += `${values[`field${i}`]}${values[`separator${i}`]}`;
    }
    newName = newName.substr(0, newName.length - 1);
  } else {
    newName = values.simpleFileName;
  }
  const request: IFileRenameData = {
    ConstructionSiteId: values.constructionSiteId,
    FileId: values.fileId,
    OldName: values.oldName,
    NewName: `${newName}.${values.extension}`,
    FolderId: breadCrumbItem.Identifier
  };
  dispatch(ListagemArquivosActions.renameFile(request));
};

export const updateFileInfo = (
  values: any,
  dispatch: Dispatch<ListagemArquivosActionUnion>
) => {
  const request: IUpdateFileInfoRequest = {
    Api: values.api,
    ConstructionSiteFk: values.constructionSiteId,
    Description: values.description,
    ApiId: values.fileId,
    FileInfoId: values.fileInfoId,
    Status: values.status
  };
  dispatch(ListagemArquivosActions.updateFileInfo([request]));
};

export const saveOrderList = (
  saveKey: string,
  dataIndex: any,
  sortOrder: boolean,
) => {
  const data: IListSaveOrder = {
    dataIndex,
    sortOrder,
  }
  AppStorage.SetItem(saveKey, JSON.stringify(data));
}

export const sortList = (
  dataIndex: any,
  sortOrder: boolean,
  fileList: IFileData[],
  dispatch: Dispatch<ListagemArquivosActionUnion>
) => {
  saveOrderList('listFilesOrder', dataIndex, sortOrder);

  const sortedFiles = [
    ...(fileList?.sort((a: IFileData, b: IFileData) => {
      const propA = prop(a, dataIndex) || '';
      const propB = prop(b, dataIndex) || '';

      if (typeof propA === 'number' && typeof propB === 'number') {
        return sortOrder ? propB - propA : propA - propB;
      }

      return (new Intl.Collator('pt', { numeric: true, sensitivity: 'base' })
        .compare(propA, propB)) * (sortOrder ? -1 : 1);
    }) || [])
  ];

  dispatch(ListagemArquivosActions.updateFileList(sortedFiles));
};

export const setTag = (text: string, color: ColorName) => <Tag color={color}>{text}</Tag>;

export const getValueField = (
  text: string,
  ordem: number,
  versionFields: IVersionField[]
) => {
  const fileNameWithoutExtension = text
    .split('.')
    .slice(0, -1)
    .join('.');
  const separators = versionFields.map(
    (field) => SeparatorsData.get(field.Separador)?.Value ?? ''
  );
  const fileNameDivisions = msplit(fileNameWithoutExtension, separators);

  return fileNameDivisions ? fileNameDivisions[ordem] : '';
};

export const getButtonsTableToolbar: (
  input: IButtonsTableToolbar
) => ITableActionTopbarButton[] = ({
  showEditTable,
  selectedRowKeys,
  selectedFiles,
  obra,
  ConstructionSiteDisciplinesId,
  listFiles,
  dispatch,
  openModalExclusaoArquivos,
  openModalDownloadMultiplesFiles,
  openModalDownloadBlockedFiles,
  onDownload,
  desarquivarArquivos,
  openModalCriarPasta,
  isDownloading,
  ObsoleteFolderId,
  breadCrumbItemId,
  isObsoleteFolder,
  constructionSiteId,
  folderId,
  folderName,
  disciplineName,
  platform,
  urlFolder,
  breadCrumbPreviousItemId,
  planIsFree,
  isLoadingFilesObsoleteList,
  currentUserPrivileges,
  userInfo,
  currentTenant,
  onOpenHistoryDrawer,
  flags,
}) => {
    if (showEditTable) {
      return [
        {
          icon: 'voltar',
          iconCustomSize: 10,
          description: 'Voltar para info',
          action: () => {
            dispatch(ListagemArquivosActions.setShowEditTable(false));
          }
        }
      ];
    }

    if (selectedRowKeys && selectedRowKeys.length) {
      return getSelectedToolbar({
        showEditTable,
        selectedRowKeys,
        selectedFiles,
        ConstructionSiteDisciplinesId,
        obra,
        listFiles,
        dispatch,
        openModalExclusaoArquivos,
        openModalDownloadMultiplesFiles,
        openModalDownloadBlockedFiles,
        onDownload,
        desarquivarArquivos,
        isDownloading,
        isObsoleteFolder,
        constructionSiteId,
        folderId,
        platform,
        urlFolder,
        breadCrumbPreviousItemId,
        planIsFree,
        currentUserPrivileges,
        onOpenHistoryDrawer,
        flags
      });
    } else {
      return getDefaultToolbar({
        showEditTable,
        selectedRowKeys,
        selectedFiles,
        obra,
        ConstructionSiteDisciplinesId,
        listFiles,
        ObsoleteFolderId,
        breadCrumbItemId,
        openModalCriarPasta,
        dispatch,
        constructionSiteId,
        folderId,
        folderName,
        disciplineName,
        platform,
        urlFolder,
        breadCrumbPreviousItemId,
        isLoadingFilesObsoleteList,
        currentUserPrivileges,
        userInfo,
        currentTenant,
        onOpenHistoryDrawer,
        flags,
      });
    }
  };

const getSelectedToolbar: (input: IButtonsTableToolbar) => ITableActionTopbarButton[] = ({
  selectedRowKeys,
  selectedFiles,
  listFiles,
  obra,
  dispatch,
  openModalExclusaoArquivos,
  openModalDownloadMultiplesFiles,
  openModalDownloadBlockedFiles,
  onDownload,
  desarquivarArquivos,
  isDownloading,
  showEditTable,
  isObsoleteFolder,
  folderId,
  breadCrumbPreviousItemId,
  planIsFree,
  currentUserPrivileges,
  onOpenHistoryDrawer,
  flags
}) => {
  const hasReleasedFile = listFiles.filter((file) => selectedRowKeys.includes(file.Identifier.ApiId || file.Identifier.ItemId)).some((file) => file.Status === 1);

  const hasUploadError = () => selectedFiles.some(file => file.UploadRenderingStatus?.archiveStatus === 'error');

  return [
    {
      icon: 'history',
      iconCustomSize: 22,
      description: 'Histórico',
      hidden: selectedRowKeys.length !== 1,
      action: () => {
        onOpenHistoryDrawer();
      }
    },
    {
      icon: 'mover',
      description: 'Mover para',
      hidden: isObsoleteFolder || !AccessPermission.editFiles(currentUserPrivileges) || hasUploadError(),
      dropdownMenu: (toggleDropdown: () => void) => (
        <MoverArquivos toggleDropdown={toggleDropdown} folderId={folderId} breadCrumbId={breadCrumbPreviousItemId} />
      )
    },
    {
      icon: 'editar',
      description: 'Edição',
      hidden: isObsoleteFolder || !AccessPermission.editSelectedFiles(currentUserPrivileges) || (selectedRowKeys.length === 1) || hasUploadError(),
      action: () => {
        dispatch(ListagemArquivosActions.setShowEditTable(!showEditTable));
      }
    },
    {
      icon: 'trocarStatus',
      description: 'Trocar status',
      hidden: isObsoleteFolder || !AccessPermission.changeStatusFiles(currentUserPrivileges) || hasUploadError(),
      dropdownMenu: (toggleDropdown: () => void) =>
        getDropdownStatusChange(selectedRowKeys, listFiles, dispatch, toggleDropdown)
    },
    {
      icon: 'menuPlotagem',
      description: 'Solicitar plotagem',
      hidden: isObsoleteFolder || !AccessPermission.requestPlotting(currentUserPrivileges) || !hasReleasedFile || planIsFree || hasUploadError(),
      action: () => {
        const hasLockedFiles = listFiles.filter((file) => selectedRowKeys.includes(file.Identifier.ApiId || file.Identifier.ItemId)).some((file) => file.Status === 0);
        let hasExtensionFiles = true;
        const extensions = [
          'dwg',
          'dxf',
          'dgn',
          'jpg',
          'pdf',
          'doc',
          'docx',
          'nwd',
          'mpp',
          'xlsm',
          'xlsx',
          'vsd',
          'vsdx'
        ];

        listFiles.filter((file) => selectedRowKeys.includes(file.Identifier.ApiId || file.Identifier.ItemId)).forEach((file) => {
          if (
            extensions.indexOf(file.Extension.toLocaleLowerCase()) === -1
          ) {
            hasExtensionFiles = false;
          }
        });

        if (hasLockedFiles) {
          toastHandler.showError(HAS_LOCKED_FILES_REQUEST_PLOT);
        }
        if (hasExtensionFiles) {
          dispatch(ListagemArquivosActions.setShowPlotRequestTable(true));
        } else {
          toastHandler.showError(HAS_LOCKED_FILES_REQUEST_PLOT_EXTENSION);
        }
      }
    },
    {
      icon: 'arquivar',
      description: 'Restaurar',
      hidden: !AccessPermission.deleteFiles(currentUserPrivileges) || !isObsoleteFolder || hasUploadError(),
      action: () => {
        if (desarquivarArquivos) {
          desarquivarArquivos();
        }
      }
    },
    {
      icon: 'excluir',
      hidden: !AccessPermission.deleteFiles(currentUserPrivileges),
      action: () => {
        if (openModalExclusaoArquivos) {
          openModalExclusaoArquivos();
        }
      }
    },
    {
      icon: 'download',
      hidden: !AccessPermission.downloadFiles(currentUserPrivileges) ||
        hasUploadError() ||
        (isObsoleteFolder && AccessPermission.isEngOrConsult()),
      isBlocked: !AccessPermission.downloadSelectBlockedFiles(currentUserPrivileges) ||
        (!AccessPermission.hasNoFolderSelected() && AccessPermission.isEngOrConsult()) ||
        (selectedRowKeys.length > 1 && AccessPermission.isEngOrConsult() && !flags.downloadViaStream),
      disabled: isDownloading,
      action: () => {
        const blockedFiles = listFiles
          .filter((file) => {
            return selectedRowKeys.includes(
              file.Identifier.ApiId || file.Identifier.ItemId
            );
          })
        const hasBlockedFiles = blockedFiles.some((file) => file.Status === 0);

        if (AccessPermission.hasNoFolderSelected()) {
          if (
            openModalDownloadBlockedFiles &&
            AccessPermission.downloadSelectBlockedFiles(currentUserPrivileges) &&
            hasBlockedFiles
          ) {
            if (selectedRowKeys.length > 1 && !flags.downloadViaStream) {
              if (AccessPermission.isEngOrConsult()) {
                if (openModalDownloadBlockedFiles) {
                  return openModalDownloadBlockedFiles(
                    false, 
                    true, 
                    false, 
                    blockedFiles.length > 1 ? true : false
                  );
                }
              } else {
                if (openModalDownloadMultiplesFiles) {
                  return openModalDownloadMultiplesFiles(false);
                }
              }
            }

            return openModalDownloadBlockedFiles(
              true,
              false,
              false,
              blockedFiles.length > 1 ? true : false
            );
          }

          if (
            openModalDownloadBlockedFiles &&
            !AccessPermission.downloadSelectBlockedFiles(currentUserPrivileges)
          ) {
            if (selectedRowKeys.length === 1 || AccessPermission.isEngOrConsult()) {
              return openModalDownloadBlockedFiles(false, true, false, false);
            }
            if (selectedRowKeys.length > 1) {
              return openModalDownloadBlockedFiles(false, false, false, true);
            }
          }

          if (selectedRowKeys.length > 1 && !flags.downloadViaStream) {
            if (AccessPermission.isEngOrConsult()) {
              if (openModalDownloadBlockedFiles) {
                return openModalDownloadBlockedFiles(false, false, false, true);
              }
            } else {
              if (openModalDownloadMultiplesFiles) {
                return openModalDownloadMultiplesFiles(false);
              }
            }
          }

          if (onDownload) return onDownload();

        } else {
          if (AccessPermission.isEngOrConsult()) {
            if (openModalDownloadBlockedFiles) {
              return openModalDownloadBlockedFiles(false, false, true, false);
            }
          } else {
            if (openModalDownloadMultiplesFiles) {
              return openModalDownloadMultiplesFiles(true);
            }
          }
        }
      }
    }
  ];
};

const getDefaultToolbar: (input: IButtonsTableToolbar) => ITableActionTopbarButton[] = ({
  showEditTable,
  listFiles,
  obra,
  ObsoleteFolderId,
  breadCrumbItemId,
  openModalCriarPasta,
  dispatch,
  constructionSiteId,
  ConstructionSiteDisciplinesId,
  folderId,
  folderName,
  disciplineName,
  platform,
  urlFolder,
  breadCrumbPreviousItemId,
  isLoadingFilesObsoleteList,
  currentUserPrivileges,
  userInfo,
  currentTenant,
  onOpenHistoryDrawer,
}) => [
    {
      icon: getIconApi(platform),
      iconCustomSize: 16,
      description: ApisEnumLabel[platform],
      hidden: !AccessPermission.downloadFiles(currentUserPrivileges) || AccessPermission.isEngOrConsult(),
      isBlocked: !urlFolder,
      action: () => {
        if (urlFolder) {
          window.open(urlFolder, '_blank', 'noopener,noreferrer');

          if (disciplineName === folderName) {
            Mixpanel.track({
              name: 'REDIRECT_SA_ACTION',
              props: {
                constructionSiteId: constructionSiteId,
                api: platform,
                disciplineId: ConstructionSiteDisciplinesId,
                disciplineApiFolderId: folderId,
                disciplineName: disciplineName,
                folderName: folderName,
              },
              userInfo,
              currentListTenant: currentTenant,
            });

          } else {
            Mixpanel.track({
              name: 'REDIRECT_SA_ACTION',
              props: {
                constructionSiteId: constructionSiteId,
                api: platform,
                disciplineId: ConstructionSiteDisciplinesId,
                disciplineApiFolderId: folderId,
                disciplineName: disciplineName,
              },
              userInfo,
              currentListTenant: currentTenant,
            });
          }
        }
      },
      dropdownMenu: urlFolder
        ? undefined
        : (toggleDropdown: () => void) => (
          <ModalBloquedSADownload
            platform={platform}
            onCancel={() => toggleDropdown()}
          />
        )
    },
    {
      icon: 'atualizar',
      iconCustomSize: 16,
      description: 'Atualizar',
      hidden: false,
      action: () => {
        const requestFileList: IListFilesRequest = {
          constructionSiteId: constructionSiteId,
          folderId: folderId,
          folderName: folderName,
          ignoreCache: true,
          isObsoleteFiles: false
        };

        if (breadCrumbPreviousItemId != null) {
          requestFileList.breadCrumbId = breadCrumbPreviousItemId;
        }

        dispatch(UpdateStatusActions.updateStatus({ requestFileList }));
      }
    },
    {
      icon: 'history',
      iconCustomSize: 22,
      description: 'Histórico',
      action: () => {
        onOpenHistoryDrawer();
      }
    },
    {
      icon: 'arquivar',
      description: 'Nova pasta',
      hidden: !AccessPermission.editFiles(currentUserPrivileges),
      action: () => {
        if (openModalCriarPasta) {
          openModalCriarPasta();
        }
      }
    },
    {
      icon: 'editar',
      description: 'Edição',
      hidden: !AccessPermission.editFiles(currentUserPrivileges),
      action: () => {
        dispatch(ListagemArquivosActions.setShowEditTable(!showEditTable));
      },
    },
    {
      icon: 'obsoleteArchive',
      iconCustomSize: 16,
      description: 'Arquivos obsoletos',
      hidden: !AccessPermission.accessObsoleteFolder() || !ObsoleteFolderId,
      disabled: isLoadingFilesObsoleteList,
      action: () => {
        if (ObsoleteFolderId && !isLoadingFilesObsoleteList) {
          openFolder(
            obra.ConstructionSiteId || 0,
            ConstructionSiteDisciplinesId,
            ObsoleteFolderId,
            obra.Api,
            breadCrumbItemId,
            true,
            'Arquivos obsoletos',
            undefined,
            dispatch,
            disciplineName
          );
        }
      },
      isLoading: isLoadingFilesObsoleteList,
      tooltipMessage: 'Não é possível ver os arquivos obsoletos enquanto houver versionamentos em andamento.',
    },
    {
      icon: 'upload',
      description: 'Upload de arquivos',
      hidden: !AccessPermission.uploadFiles(currentUserPrivileges),
      action: () => {
        document.getElementById('uploadFiles')?.click();
      }
    }
  ];

export const getButtonsPlotRequestTableToolbar: (
  allFilesCount: number,
  selectedFiles: number[],
  deleteRow: (index: number) => void,
  dispatch: React.Dispatch<any>,
  QRCodeIdPosition: QRCodePositionEnum,
  onQRCodeIdPosition: (id: number) => void,
  onSubmit: (
    allQrCode: boolean,
    allColors: boolean,
    allCopysCount: number,
    selectedFiles?: number[],
  ) => void,
) => ITableActionTopbarButton[] = (
  allFilesCount: number,
  selectedFiles: number[],
  deleteRow: (index: number) => void,
  dispatch: React.Dispatch<any>,
  QRCodeIdPosition: QRCodePositionEnum,
  onQRCodeIdPosition: (id: number) => void,
  onSubmit: (
    allQrCode: boolean,
    allColors: boolean,
    allCopysCount: number,
    selectedFiles?: number[],
  ) => void,
) => {
    return [
      {
        icon: QRCodePositionEnum[QRCodeIdPosition] as IconName,
        description: 'QR Code',
        showArrow: true,
        hidden: !!selectedFiles.length && (selectedFiles.length !== allFilesCount),
        action: () => undefined,
        dropdownMenu: (toggleDropdown: () => void) => (
          <QRCodePosition
            QRCodeIdPosition={QRCodeIdPosition}
            onQRCodeIdPosition={onQRCodeIdPosition}
          />
        )
      },
      {
        icon: 'checkedOutline',
        description: !!selectedFiles.length && (selectedFiles.length !== allFilesCount)
          ? 'Aplicar'
          : 'Aplicar a todos',
        showArrow: true,
        action: () => undefined,
        dropdownMenu: (toggleDropdown: () => void) => (
          <LotePlotagens
            allFilesCount={allFilesCount}
            selectedFiles={selectedFiles}
            onSubmit={onSubmit}
            onClose={toggleDropdown}
          />
        )
      },
      {
        icon: 'excluir',
        description: '',
        hidden: !selectedFiles.length,
        action: () => {
          selectedFiles.forEach((index) => deleteRow(index));
          dispatch(ListagemArquivosActions.setSelectedPlotRequestRowKeys([]));
        }
      },
    ];
  };

const changeStatusFiles = (
  keys: string[],
  listFiles: IFileData[],
  dispatch: React.Dispatch<any>,
  status: FileStatusEnum,
  toggleDropdown: () => void
) => {
  const request = listFiles.filter((file) => keys.includes(file.Identifier.ApiId || file.Identifier.ItemId)).map((file) => ({
    ...file.FileInfo,
    Status: status,
    isStatusEdit: true,
  }));
  dispatch(ListagemArquivosActions.updateFileInfo(request));
  toggleDropdown();
};

const getDropdownStatusChange = (
  keys: string[],
  listFiles: IFileData[],
  dispatch: React.Dispatch<any>,
  toggleDropdown: () => void
) => (
  <div className={styles['menuStatusChange']}>
    <div
      className={styles['options']}
      onClick={() =>
        changeStatusFiles(
          keys,
          listFiles,
          dispatch,
          FileStatusEnum.Bloqueado,
          toggleDropdown
        )
      }
    >
      Bloqueado
    </div>
    <div
      className={styles['options']}
      onClick={(e) =>
        changeStatusFiles(
          keys,
          listFiles,
          dispatch,
          FileStatusEnum.Liberado,
          toggleDropdown
        )
      }
    >
      Liberado
    </div>
  </div>
);
export const getDragInfo = (folderName?: string) => (
  <div className={styles['dragInfoWrap']}>
    <img src={emptyListImage} alt="Upload arquivos" />
    <div className={styles['folderDescriptionWrap']}>
      <div>Arraste os arquivos para fazer upload em: </div>
      <div className={styles['folderName']}>{folderName}</div>
    </div>
  </div>
);

export const getDragError = (limitUpload?: number, folderName?: string) => (
  <div className={styles['dragErrorInfoWrap']}>
    <img src={emptyListImage} alt="Upload arquivos" />
    <div className={styles['folderDescriptionWrap']}>
      <div>
        {`Atenção: Não é possível realizar o upload de mais de ${limitUpload ? limitUpload : 10} arquivos simultaneamente`}
      </div>
      <div className={styles['folderName']}>{folderName}</div>
    </div>
  </div>
);

export const handleOpenFileLink = async (
  path: string,
  dispatch: Dispatch<any>,
) => {
  await dispatch(ViewerActions.setOpenFileLink(''));
  history.push(path);
}

export const openFolder = (
  csId: number,
  ConstructionSiteDisciplinesId: number,
  folderId: string,
  obraApi?: number,
  breadCrumbId?: number | string,
  isObsoleteFolder?: boolean,
  folderName?: string,
  event?: number,
  dispatch?: Dispatch<any>,
  disciplineName?: string | null,
) => {
  let queryString = '';
  queryString += breadCrumbId
    ? `${queryString ? '&' : '?'}breadCrumbId=${breadCrumbId}`
    : '';
  queryString += isObsoleteFolder
    ? `${queryString ? '&' : '?'}isObsoleteFolder=${isObsoleteFolder}`
    : '';
  queryString += folderName
    ? `${queryString ? '&' : '?'}folderName=${folderName}`
    : '';
  queryString += obraApi
    ? `${queryString ? '&' : '?'}obraApi=${obraApi}`
    : '';
  queryString += ConstructionSiteDisciplinesId
    ? `${queryString ? '&' : '?'}ConstructionSiteDisciplinesId=${ConstructionSiteDisciplinesId}`
    : '';
  queryString += disciplineName
    ? `${queryString ? '&' : '?'}DisciplineName=${disciplineName}`
    : '';

  if (!event && dispatch) {
    handleOpenFileLink(
      `/obras/filelist/${csId}/${folderId}${queryString}`,
      dispatch
    );
  } else {
    if (dispatch) {
      dispatch(ViewerActions.setOpenFileLink(`/obras/filelist/${csId}/${folderId}${queryString}`));
    }
  }
};

export const openFile = (
  csId: number,
  ConstructionSiteDisciplinesId: number,
  record: IFileData,
  constructionSiteName: string,
  breadCrumbId: number | string,
  dispatch: Dispatch<any>,
  constructionSiteApiId: number,
  event: number,
  userInfo?: IUserInforResponse,
  currentTenant?: ITenantData | null,
  disciplineName?: string | null,
  openModalVisualizationBlockedFiles?: (hasPermission: boolean) => void,
  setOpenFileVisualizationLinkStatus?: Dispatch<SetStateAction<IOpenVisualizationBlockedFileAction>>,
) => {
  const apiId = record.Identifier.ApiId || record.FileInfo.ApiId;

  if (extensions2dAnd3d.indexOf(record.Extension.toLocaleLowerCase()) !== -1) {
    if (record.RenderingStatus === 1 || (record.RenderingStatus === 2 && record.RenderingSignalR.etapa > 0)) {
      if ((record.Status === 0) && openModalVisualizationBlockedFiles && setOpenFileVisualizationLinkStatus) {
        if (AccessPermission.isEngOrConsult()) {
          return openModalVisualizationBlockedFiles(false);
        }
        setOpenFileVisualizationLinkStatus({
          path: `/viewer/1/${csId}/${record.FileInfo.ApiId}/${breadCrumbId}?obraApi=${constructionSiteApiId}${ConstructionSiteDisciplinesId ? `&ConstructionSiteDisciplinesId=${ConstructionSiteDisciplinesId}` : ''}`,
          newWindown: event === 2,
        })
        return openModalVisualizationBlockedFiles(true);
      }
      if (dispatch && event === 2) {
        dispatch(ViewerActions.setOpenFileLink(`/viewer/1/${csId}/${record.FileInfo.ApiId}/${breadCrumbId}?obraApi=${constructionSiteApiId}${ConstructionSiteDisciplinesId ? `&ConstructionSiteDisciplinesId=${ConstructionSiteDisciplinesId}` : ''}`));
      } else {
        handleOpenFileLink(
          `/viewer/1/${csId}/${record.FileInfo.ApiId}/${breadCrumbId}?obraApi=${constructionSiteApiId}${ConstructionSiteDisciplinesId ? `&ConstructionSiteDisciplinesId=${ConstructionSiteDisciplinesId}` : ''}`,
          dispatch
        );
      }
    }
    else if (record.RenderingStatus === 3 && event !== 2) {
      const request: IAutodeskViewer = {
        ConstructionSiteId: csId,
        FileApiId: apiId
      };

      dispatch(ListagemArquivosActions.setFileStatusReRendering(request));
      dispatch(ViewerActions.autoDeskViewer(request));

      Mixpanel.track({
        name: 'JOB_RENDER_FILE',
        props: {
          constructionSiteId: csId,
          api: constructionSiteApiId,
          disciplineId: ConstructionSiteDisciplinesId,
          fileId: apiId,
          disciplineName: disciplineName,
        },
        userInfo,
        currentListTenant: currentTenant,
      });
    }
    else if ((record.RenderingStatus === null || record.RenderingStatus === 0) && event !== 2) {
      const request: IAutodeskViewer = {
        ConstructionSiteId: csId,
        FileApiId: apiId
      };

      dispatch(ListagemArquivosActions.setFileStatusRendering(request));
      dispatch(ViewerActions.autoDeskViewer(request));

      Mixpanel.track({
        name: 'JOB_RENDER_FILE',
        props: {
          constructionSiteId: csId,
          api: constructionSiteApiId,
          disciplineId: ConstructionSiteDisciplinesId,
          fileId: apiId,
          disciplineName: disciplineName,
        },
        userInfo,
        currentListTenant: currentTenant,
      });
    }

  } else if (allOtherExtensions.indexOf(record.Extension.toLocaleLowerCase()) !== -1) {
    if ((record.Status === 0) && openModalVisualizationBlockedFiles && setOpenFileVisualizationLinkStatus) {
      if (AccessPermission.isEngOrConsult()) {
        return openModalVisualizationBlockedFiles(false);
      }
      setOpenFileVisualizationLinkStatus({
        path: `/viewer/2/${csId}/${record.FileInfo.ApiId}/${breadCrumbId}?obraApi=${constructionSiteApiId}${ConstructionSiteDisciplinesId ? `&ConstructionSiteDisciplinesId=${ConstructionSiteDisciplinesId}` : ''}`,
        newWindown: event === 2,
      })
      return openModalVisualizationBlockedFiles(true);
    }
    if (dispatch && event === 2) {
      dispatch(ViewerActions.setOpenFileLink(`/viewer/2/${csId}/${record.FileInfo.ApiId}/${breadCrumbId}?obraApi=${constructionSiteApiId}${ConstructionSiteDisciplinesId ? `&ConstructionSiteDisciplinesId=${ConstructionSiteDisciplinesId}` : ''}`));
    } else {
      handleOpenFileLink(
        `/viewer/2/${csId}/${record.FileInfo.ApiId}/${breadCrumbId}?obraApi=${constructionSiteApiId}${ConstructionSiteDisciplinesId ? `&ConstructionSiteDisciplinesId=${ConstructionSiteDisciplinesId}` : ''}`,
        dispatch
      );
    }
  } else {
    toastHandler.showError('Arquivo inválido');
  }
};

export const getFilterOptions = (
  fileList: any[],
  key: string,
  render?: (value: any) => string,
  validation?: (index: any) => boolean,
  renderNode?: (value: any) => ReactNode
) => {
  const types: any = {};
  const filterList: ITipoOption[] = [];
  fileList?.forEach((file) => {
    if (file[key] === undefined || file[key] === null) {
      return;
    }

    const updateIndex = render ? render(file[key]) : file[key];
    if (
      types[updateIndex] === undefined &&
      (!validation || (validation && validation(updateIndex)))
    ) {
      types[updateIndex] = true;
      filterList.push({
        description: updateIndex.toString(),
        renderNode: renderNode && renderNode(updateIndex),
        value: updateIndex.toString()
      });
    }
  });
  return filterList;
};

export const getSecondPlanUploadFiles = (data: ISignalRArchive) => {
  if (data?.archiveStatus === 'error') {
    return (
      <Tooltip overlay={<div>Erro ao processar o upload</div>}>
        <div>
          <Icon
            icon="error"
            customSize={15}
            color="vermelho"
            style={{ cursor: 'pointer' }}
          />
        </div>
      </Tooltip>
    )
  }

  if (data.archiveStatus === 'success') {
    return (
      <Tooltip overlay={<div>Upload finalizado</div>}>
        <div>
          <Icon
            icon="confirmacao"
            customSize={15}
            color="verde"
          />
        </div>
      </Tooltip>
    )
  }

  if (data.archiveStatus === 'sendingStorage') {
    return (
      <Tooltip overlay={<div>{`Finalizando upload (${data.progress}%)`}</div>}>
        <div>
          <CircularProgressbar
            className={styles['secondPlanLoading']}
            strokeWidth={14}
            styles={buildStyles({ pathColor: '#3547C8', trailColor: '#EDEFF2' })}
            value={data.progress / 100}
            maxValue={1}
          />
        </div>
      </Tooltip>
    )
  }
}
