import { FC, useState } from "react";
import { useSelector } from "react-redux";
import { getTheme } from "Store/MultiDomain/MultiDomain.selector";
import { Icon } from "Components/UI";
import { Checkbox, ConfigProvider } from "antd";
import Button from "Components/UI/Button";
import { Container, Counter } from "./styles";

interface ILotePlotagens {
  allFilesCount: number;
  selectedFiles: number[];
  onSubmit: (
    allQrCode: boolean,
    allColors: boolean,
    allCopysCount: number,
    selectedFiles?: number[],
  ) => void;
  onClose: () => void;
}

const LotePlotagens: FC<ILotePlotagens> = ({
  allFilesCount,
  selectedFiles,
  onSubmit,
  onClose,
}) => {
  const theme = useSelector(getTheme);

  const [allQrCode, setAllQrCode] = useState(true);
  const [allColors, setAllColors] = useState(false);
  const [allCopysCount, setAllCopysCount] = useState(0);

  const handleCounter = (value: number) => {
    setAllCopysCount(prev => {
      const newValue = prev + value;
      if (newValue < 0) return prev;
      return newValue;
    });
  };

  const handleSubmit = () => {
    const notSelecteds = selectedFiles.length === 0 ||
      selectedFiles.length === allFilesCount;
    onSubmit(
      allQrCode,
      allColors,
      allCopysCount,
      notSelecteds ? undefined : selectedFiles,
    );
    onClose();
  };

  const isBtnDisabled = allQrCode && !allColors && allCopysCount === 0;

  return (
    <Container>
      <div className="titleLote">
        <span className="textLote">
          {!!selectedFiles.length && (selectedFiles.length !== allFilesCount)
            ? 'Aplicar a selecionados'
            : 'Aplicar a todos'
          }
        </span>
      </div>

      <ConfigProvider theme={{
        token: {
          colorBgContainerDisabled: `color-mix(in srgb, ${theme.colors.primary.primaryOutline}, ${theme.colors.surface.surface})`,
          colorTextDisabled: theme.colors.primary.onPrimary,
          colorPrimary: theme.colors.primary.primary
        }
      }}>
        <div className="mainLote">
          <span className="mainLoteLeft">
            <Icon
              icon="qrCode"
              customSize={16}
              className="iconLote"
            />
            <span className="labelLote">QR Code</span>
          </span>
          <Checkbox
            name={`isQrCode`}
            checked={allQrCode}
            onChange={e => setAllQrCode(e.target.checked)}
          />
        </div>
        <div className="mainLote">
          <span className="mainLoteLeft">
            <Icon
              icon="palette"
              customSize={16}
              className="iconLote"
            />
            <span className="labelLote">Colorido</span>
          </span>
          <Checkbox
            name={`isQrCode`}
            checked={allColors}
            onChange={e => setAllColors(e.target.checked)}
          />
        </div>
        <div className="mainLote">
          <span className="mainLoteLeft">
            <Icon
              icon="copiar"
              customSize={16}
              className="iconLote"
            />
            <span className="labelLote">Cópias</span>
          </span>
          <Counter active={allCopysCount > 0}>
            <Icon
              icon="subtrair"
              className="iconCounter"
              onClick={() => handleCounter(-1)}
            />
            <span className="textCounter">{allCopysCount}</span>
            <Icon
              icon="adicionar"
              className="iconCounter"
              onClick={() => handleCounter(1)}
            />
          </Counter>
        </div>
      </ConfigProvider>

      <div className="footerLote">
        <Button
          type="primary"
          disabled={isBtnDisabled}
          className="btnLote"
          onClick={handleSubmit}
        >
          Aplicar
        </Button>
      </div>
    </Container>
  )
}

export default LotePlotagens;
