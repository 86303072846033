import { FC } from 'react';
import NomenclaturaForm from '../../../../../Components/UI/NomenclaturaForm';
import { NomenclatureContainer } from 'Components/UI/NomenclaturaForm/styles';
import { INomenclatureData } from 'Data/interfaces/Nomenclatura/INomenclatureData';

export interface INomenclaturaTab {
  isSubmited?: boolean;
  isFirstObra?: boolean;
  obraVersionData?: INomenclatureData;
  versionConfigFk?: number;
  onSubmit: (props?: object, erros?: string[]) => void;
}

const NomenclaturaTab: FC<INomenclaturaTab> = (props) => {
  return (
    <NomenclatureContainer>
      <NomenclaturaForm
        isSubmited={props.isSubmited}
        isTabVersion={true}
        isFirstObra={props.isFirstObra}
        obraVersionData={props.obraVersionData}
        versionConfigFk={props.versionConfigFk}
        onSubmit={props.onSubmit}
      />
    </NomenclatureContainer>
  );
};

export default NomenclaturaTab;
